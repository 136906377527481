<template>
<b-card class="w-100 mb-4" no-body>
    <b-card-body>
        <b-form>
                        <b-form-group>
                          <b-form-input
                            id="txt-fullname"
                            type="text"
                            placeholder="Enter fullname"
                            class="mb-3"
                            v-model="$v.form.full_name.$model"
                            :state="validateState('full_name')"
                          ></b-form-input>
                          <b-form-invalid-feedback id="txt-fullname"
                            >This is a required field</b-form-invalid-feedback
                          >
                        </b-form-group>
                        <b-form-group>
                          <b-form-input
                            id="txt-email"
                            type="text"
                            placeholder="Enter Email"
                            class="mb-3"
                            v-model="$v.form.email.$model"
                            :state="validateState('email')"
                          ></b-form-input>
                          <b-form-invalid-feedback id="txt-email"
                            >This is a required field</b-form-invalid-feedback
                          >
                        </b-form-group>
                        <b-form-group>
                          <b-form-input
                            id="txt-phone"
                            type="text"
                            placeholder="Enter Phone"
                            class="mb-3"
                            v-model="$v.form.phone.$model"
                            :state="validateState('phone')"
                          ></b-form-input>
                          <b-form-invalid-feedback id="txt-email"
                            >This is a required field</b-form-invalid-feedback
                          >
                        </b-form-group>
                        <b-form-group>
                          <b-form-input
                            id="txt-password"
                            type="password"
                            placeholder="Enter Password"
                            class="mb-3"
                            v-model="$v.form.password.$model"
                            :state="validateState('password')"
                          ></b-form-input>
                          <b-form-invalid-feedback id="txt-password"
                            >This is a required field</b-form-invalid-feedback
                          >
                        </b-form-group>

                        <b-button pill variant="primary" class="px-4" :disabled="$v.form.$invalid" @click="register()">Save</b-button>
                      </b-form>
    </b-card-body>
</b-card>
</template>

<script>
import { mapActions } from "vuex";
import { validationMixin } from "vuelidate";
import {
  required,
  minLength,
  email,
  maxLength,
} from "vuelidate/lib/validators";

export default {
    name: "Add_Inspector",
    mixins: [validationMixin],
  data: () => ({
    form: {
      full_name: null,
      password: null,
      email: null,
      phone: null,
      user_type: 5
    },
    checkbox: false,
  }),
  computed: {},
  validations: {
    form: {
      full_name: {
        required,
        minLength: minLength(4),
      },
      email: {
        required,
        email,
        maxLength: maxLength(20),
      },
      password: {
        required,
        minLength: minLength(8),
      },
      phone: {
        required,
        minLength: minLength(8),
      }
    },
  },
  methods: {
    ...mapActions(["registerMe"]),
    validateState(full_name) {
      const { $dirty, $error } = this.$v.form[full_name];
      return $dirty ? !$error : null;
    },
    register(){
      this.registerMe(this.form).then((res) => {
        if(res && 'status' in res && res.status >= 400){
          this.$bvToast.toast(res.data, {
            title: "Something Went Wrong",
            variant: 'danger',
            solid: true
          })
        }
        else{
          this.$bvToast.toast(res.data, {
            title: "Success",
            variant: 'success',
            solid: true
          })
        }
      })
    }
  },
};
</script>
